import React from "react"

export const HTMLContent = ({ content, className }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
)

const Content = ({ content, className, children }) => (
  <div className={className}>{content || children }</div>
)


export default Content
