import React from 'react';
import Layout from "./layout"
import Content, { HTMLContent } from "./Content"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "../components/seo"

const BlogPostTemplate = ({
  content,
  contentComponent,
  title,
  excerpt,
  date,
  author,
  isPreview,
  thumbnail
}) => {
  let PostContent = contentComponent || HTMLContent
  let LayoutComponent = Layout
  if (isPreview) { 
    PostContent = Content
    LayoutComponent = Content
  }
  /**
   * LayoutComponent's className should pass only if isPreview is true
   */
  return (
    <LayoutComponent className="flex-grow-1"> 
      <SEO article="true" title={title} description={excerpt} />
      <div className="container bg-white">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h1>{title}</h1>
            <h2>{date}</h2>
            <p>{author}</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className='col-md-8'>
            <GatsbyImage image={getImage(thumbnail)} className="w-100" alt="chef" />
          </div>
        </div>
        <div className="row justify-content-center">
          <article className="col-md-6">
            <PostContent className="blog-post-content" content={content}/>
          </article>
        </div>
      </div>
    </LayoutComponent>
  )
}

export default BlogPostTemplate