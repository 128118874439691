import React from "react"
import BlogPostTemplate from "../components/blogTemplate"
import { graphql } from "gatsby"

const blogPost = ({ data }) => {
  //const data = useStaticQuery
  const { markdownRemark, file } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  return (<BlogPostTemplate content= {html} {...frontmatter} title = {frontmatter.title} thumbnail={file} excerpt={excerpt}/>)
}

export default blogPost

/**
 * Make sure to avoid any $thumbnail same names conflicts
 * Or you should add new sourceInstanceName filter
 */
export const query = graphql`
query($slug: String!, $thumbnail: String!) {
  markdownRemark(frontmatter: { path: { eq: $slug } }) {
    html
    excerpt
    frontmatter {
      date
      path
      title
      author
      thumbnail
    }
  }
  file(relativePath: {eq: $thumbnail}) {
		childImageSharp {
			gatsbyImageData(width: 400, height: 600, quality: 100, formats: [JPG])
    }
  }
}
`